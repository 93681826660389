<!--
 * @Author: xiaziwen
 * @Date: 2021-01-25 16:47:48
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-25 16:55:44
 * @FilePath: \crm-education\src\layouts\c\ProtalFooter.vue
-->
<template>
  <div
    class="protal-footer d-flex justify-between align-center py-40"
    v-if="domainSettings"
    v-html="domainSettings.portalCopyright"
  >
    <!-- <div
      class="footer-left d-flex col justify-center align-start fs-14 color-white"
      
    ></div>
    <div class="footer-left d-flex col justify-center align-end">
      <p class="fs-14 color-white">全国统一服务热线</p>
      <p class="fs-14 color-white">0575-85929370</p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'protal_layout_footer',
  data() {
    return {}
  },
  computed: {
    ...mapState(['domainSettings'])
  }
}
</script>

<style lang="scss" scoped>
.protal-footer {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
