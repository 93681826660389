export const getCurrentByCode = (list, columnsTypeCode) => {
  if (columnsTypeCode === ['/protal'] || columnsTypeCode === null) {
    return null
  } else {
    const info = list.find(c => c.columnsTypeCode === columnsTypeCode)
    return info || null
  }
}
export const getCurrentByColumnsId = (list, columnsId) => {
  if (columnsId === null) {
    return null
  }
  const info = list.find(c => c.columnsId === columnsId)
  return info || null
}

export const getSubMenuByCode = (list, columnsTypeCode) => {
  const info = list.find(c => c.columnsTypeCode === columnsTypeCode)
  const parentColumnsId = info.parentColumnsId
  const menus = list
    .filter(c => c.parentColumnsId === parentColumnsId)
    .sort((a, b) => a.columnsOrderNo - b.columnsOrderNo)
  return menus
}
