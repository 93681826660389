<!--
 * @Author: xiaziwen
 * @Date: 2021-01-24 16:37:46
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-25 16:57:57
 * @FilePath: \crm-education\src\layouts\Protal.vue
-->
<template>
  <div class="protal-layout">
    <div class="protal-layout-header">
      <protal-header></protal-header>
    </div>
    <div class="protal-layout-content" v-if="columnsInfo.length > 0">
      <router-view></router-view>
    </div>
    <div class="protal-layout-footer">
      <!-- <div class="footer-icon-link link"></div> -->
      <protal-footer></protal-footer>
    </div>
  </div>
</template>

<script>
import ProtalHeader from './c/ProtalHeader'
import ProtalFooter from './c/ProtalFooter'
import { mapState } from 'vuex'

export default {
  name: 'protal_layout',
  components: {
    ProtalHeader,
    ProtalFooter
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['columnsInfo'])
  }
}
</script>

<style lang="scss" scoped>
.protal-layout {
  min-height: 100vh;
  .protal-layout-content {
    min-height: calc(100vh - 214px);
  }
  .protal-layout-footer {
    height: 150px;
    background-color: #444;
    position: relative;
    .footer-icon-link {
      position: absolute;
      left: 20px;
      bottom: 20px;
      width: 20px;
      height: 20px;
      background-color: #999;
    }
  }
}
</style>
