<!--
 * @Author: xiaziwen
 * @Date: 2021-01-24 16:38:34
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-25 17:36:32
 * @FilePath: \crm-education\src\layouts\c\ProtalHeader.vue
-->
<template>
  <div class="protal-header" v-wechat-title="protalTitle">
    <div class="protal-logo-wrap">
      <img :src="domainSettings.portalLogoImg" alt="" v-if="domainSettings && domainSettings.portalLogoImg" />
      <h3 v-else>{{ domainSettings ? domainSettings.organizationName : '' }}</h3>
    </div>
    <div class="protal-menu">
      <a-menu v-model="current" mode="horizontal" @click="onMenuClick">
        <a-menu-item key="/protal">首页</a-menu-item>
        <template v-for="m in menu">
          <a-sub-menu v-if="m.children" :key="m.columnsTypeCode" :title="m.columnsName">
            <a-menu-item v-for="sm in m.children" :key="sm.columnsTypeCode">{{ sm.columnsName }}</a-menu-item>
          </a-sub-menu>
          <!--<a-menu-item v-else :key="m">招生工作</a-menu-item>-->
        </template>
      </a-menu>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapState } from 'vuex'
import { getCurrentByCode, getCurrentByColumnsId } from '@/utils/protal'

export default {
  name: 'protal_header',
  data() {
    return {
      organizationId: null,
      protalLogoImg: '',
      menu: [],
      menuCurrent: [],
      protalTitle: ''
    }
  },
  computed: {
    ...mapState(['menuKeyPath', 'columnsInfo', 'domainSettings']),
    current: {
      get() {
        return this.menuKeyPath
      },
      set(val) {
        this.$store.commit('setMenuKeyPath', this.menuCurrent.keyPath)
      }
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      // this.current = [this.$route.path]
      await this.initOrganizationByDomain()
      await this.initColumnInfo()
      await this.initDetailMenuKey()
    },
    async initOrganizationByDomain() {
      const domain = process.env.NODE_ENV === 'development' ? 100000 : window.location.origin
      let res = await api.protal.getOrganizationByDomain({ domain })
      if (res.code === 200 && res.data) {
        this.organizationId = res.data.organizationId
        this.protalTitle = res.data.organizationName || ''
        localStorage.setItem('organizationId', res.data.organizationId)
        this.$store.commit('setDomainSettings', res.data)
      } else {
        //TODO: 测试用
        if (
          process.env.NODE_ENV === 'development' ||
          (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_BASE_URL)
        ) {
          this.organizationId = 100000
          localStorage.setItem('organizationId', 100000)
        }
      }
      // TODO: 动态设置图标
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = `./${this.organizationId}.ico`
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    async initDetailMenuKey() {
      const { columnsTypeCode } = this.$route.query
      const menuKeyPath = this.getMenuKeyPathByCode(columnsTypeCode)
      this.$store.commit('setMenuKeyPath', menuKeyPath)
    },
    getMenuKeyPathByCode(columnsTypeCode) {
      if (this.$route.path === '/protal') {
        return ['/protal']
      }
      const current = getCurrentByCode(this.columnsInfo, columnsTypeCode)
      const parent = getCurrentByColumnsId(this.columnsInfo, current.parentColumnsId)
      return [parent.columnsTypeCode, columnsTypeCode]
    },
    async onMenuClick(menu) {
      this.menuCurrent = menu
      const current = getCurrentByCode(this.columnsInfo, menu.key)
      if (menu.key !== '/protal') {
        let path = '/protal/list'
        if (current.columnsShowModel == 1) {
          path = '/protal/list'
          this.$router.push({
            path: path,
            query: { columnsTypeCode: menu.keyPath[0] }
          })
        } else {
          path = '/protal/list/detail'
          let res = await api.protal.getInfoByColumnId({
            columnsId: current.columnsId
          })
          if (res.code === 200 && res.data.length === 1) {
            this.$router.push({
              path: path,
              query: {
                columnsTypeCode: menu.keyPath[0],
                columnsId: current.columnsId,
                informationId: res.data[0].informationId
              }
            })
          } else {
            this.$router.push({
              path: path,
              query: {
                columnsTypeCode: menu.keyPath[0],
                columnsId: current.columnsId
              }
            })
          }
        }
      } else {
        this.$router.push({
          path: '/protal'
        })
      }
    },
    async initColumnInfo() {
      let res = await api.protal.getColumns({
        organizationId: this.organizationId
      })
      if (res.code === 200) {
        this.$store.commit('setColumnsInfo', res.data)
        this.menu = this.getMenuTreeByColumns(res.data)
      }
    },
    getMenuTreeByColumns(data = []) {
      if (!data || !data.length) {
        return []
      }
      data.sort((a, b) => a.columnsOrderNo - b.columnsOrderNo)
      let levels = Array.from(new Set(data.map(item => item.columnsLevel).sort()))
      let menu = []
      while (levels.length > 0) {
        getMenuData(menu, data, levels.shift())
      }

      function getMenuData(menu, data, level) {
        // [], data, 1
        data.forEach(item => {
          if (item.columnsLevel == level) {
            if (menu.length === 0) {
              menu.push(item)
            } else {
              const parent = menu.find(m => m.columnsId === item.parentColumnsId)

              if (!parent) {
                menu.push(item)
              } else {
                if (!parent['children']) {
                  parent['children'] = []
                }
                parent['children'].push(item)
              }
            }
          }
        })
      }
      return menu
    }
  }
}
</script>

<style lang="scss" scoped>
.protal-header {
  max-width: 1200px;
  margin: 0 auto;
  .protal-logo-wrap {
    height: 72px;
    width: 248px;
    display: inline-block;
    vertical-align: top;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .ant-menu-horizontal {
    border: none;
  }
  .protal-menu {
    .ant-menu {
      font-size: 18px !important;
    }
    display: inline-block;
    width: calc(100% - 248px);
    text-align: right;
    ::v-deep .ant-menu-submenu-title {
      &:hover {
        color: #fa3f3f !important;
      }
    }
    .ant-menu-submenu {
      height: 72px;
      line-height: 72px;
      &:hover {
        color: #fa3f3f;
        border-bottom: 4px solid #fa3f3f;
      }
      &.ant-menu-submenu-open {
        color: #fa3f3f;
        border-bottom: 4px solid #fa3f3f;
      }
      &.ant-menu-submenu-active {
        color: #fa3f3f;
        border-bottom: 4px solid #fa3f3f;
      }
      &.ant-menu-submenu-selected {
        color: #fa3f3f;
        border-bottom: 4px solid #fa3f3f;
      }
    }
    .ant-menu-item {
      height: 72px;
      line-height: 72px;
      &.ant-menu-item-active {
        color: #fa3f3f;
        border-bottom: 4px solid #fa3f3f;
      }
      &.ant-menu-item-selected {
        color: #fa3f3f;
        border-bottom: 4px solid #fa3f3f;
      }
    }
  }
}
</style>
